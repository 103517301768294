// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import GcseTest from './Gcse-test';
import GcseAdvice from './Gcse-advice';
import AlevelTest from './Alevel-test';
import AlevelAdvice from './Alevel-advice';
import DegreeTest from './Degree-test';
import DegreeAdvice from './Degree-advice';
import FirstjobTest from './Firstjob-test';
import FirstjobAdvice from './Firstjob-advice';
import MidcareerTest from './Midcareer-test';
import MidcareerAdvice from './Midcareer-advice';
import AboutUs from './AboutUs';
import Contact from './Contact';
import Terms from './Terms-and-conditions';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Gcse_test" element={<GcseTest />} />
        <Route path="/Gcse_advice" element={<GcseAdvice />} />
        <Route path="/Alevel_test" element={<AlevelTest />} />
        <Route path="/Alevel_advice" element={<AlevelAdvice />} />
        <Route path="/Degree_test" element={<DegreeTest />} />
        <Route path="/Degree_advice" element={<DegreeAdvice />} />
        <Route path="/Firstjob_test" element={<FirstjobTest />} />
        <Route path="/Firstjob_advice" element={<FirstjobAdvice />} />
        <Route path="/Midcareer_test" element={<MidcareerTest />} />
        <Route path="/Midcareer_advice" element={<MidcareerAdvice />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Terms-and-conditions" element={<Terms />} />

      </Routes>
    </Router>
  );
}

export default App;
