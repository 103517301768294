// src/Contact.js

import React from 'react';
import aboutUsImage from './assets/clean_logo.JPG'; // Import the image
import { useNavigate } from 'react-router-dom';

function Contact() {

  const navigate = useNavigate();

  // Define the handleClick function to navigate to different paths
  const handleClick = (path) => {
    navigate(path);
  };
  window.scrollTo(0, 0);
  return (
    <div className="contact-container">
      <h1>Contact Us</h1>
      <img src={aboutUsImage} alt="About Us" className="about-us-image" />
      <p>If you have any questions, feel free to reach out to us!</p>
      <p>Email: info@studentcareerquiz.co.uk</p>
      <p>TikTok: @studentcareerquiz </p>

      <button onClick={() => handleClick('/')}className="button-home">Home Page </button>  

      <div className="footer">
        <h3>____________________________________________________________</h3>
        <ul className="footer-links">
          <li onClick={() => handleClick('/AboutUs')}>About Us</li>
          <li onClick={() => handleClick('/Contact')}>Contact</li>
          <li onClick={() => handleClick('/Terms-and-conditions')}>Terms & Conditions</li>
        </ul>
        <p>&copy; 2024 www.studentcareerquiz.co.uk. All rights reserved.</p>
      </div>
      
    </div>
  );
}

export default Contact;
