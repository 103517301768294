// src/Degree-test.js

import React from 'react';
import Survey from './Survey';
import { useNavigate } from 'react-router-dom';
import './App.css'; // Ensure App.css is imported
import aboutUsImage from './assets/clean_logo.JPG'; // Import the image


function DegreeTest() {
  const navigate = useNavigate();
  // Define the handleClick function to navigate to different paths
  const handleClick = (path) => {
    navigate(path);
  };
  window.scrollTo(0, 0);
  return (
    <div className="container">
      <div className="title-container">
      <h1>Degree Choices Quiz</h1>
      <img src={aboutUsImage} alt="About Us" className="about-us-image" />
      <p>This quiz is designed to help you explore your career options and subjects best suited to you.</p>
      <p>The responses will provide some ideas about the careers and related subjects to study at degree level.</p>
      <Survey careerStage="degree" />
      <button onClick={() => handleClick('/')}className="button-home">Home Page </button>  
      </div>

      <div className="footer">
        <h3>____________________________________________________________</h3>
        <ul className="footer-links">
          <li onClick={() => handleClick('/AboutUs')}>About Us</li>
          <li onClick={() => handleClick('/Contact')}>Contact</li>
          <li onClick={() => handleClick('/Terms-and-conditions')}>Terms & Conditions</li>
        </ul>
        <p>&copy; 2024 www.studentcareerquiz.co.uk. All rights reserved.</p>
      </div>
      
    </div>
  );
}

export default DegreeTest;



