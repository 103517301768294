// src/Disclaimer.js
import './App.css'; // Ensure App.css is imported
import React from 'react';
import aboutUsImage from './assets/clean_logo.JPG'; // Import the image
import { useNavigate } from 'react-router-dom';

function Terms() {

  const navigate = useNavigate();

  // Define the handleClick function to navigate to different paths
  const handleClick = (path) => {
    navigate(path);
  };
  window.scrollTo(0, 0);
  return (
    <div className="terms-container">
      <h1>Terms and Conditions of Use</h1>
      <img src={aboutUsImage} alt="About Us" className="about-us-image" />
      <p> The conditions set out in these terms of use are applicable to the following website: www.studentcareerquiz.co.uk (hereinafter ‘the website’). Use of the website means full compliance with these terms. </p>
      <p> The website is owned by David Bray, situated in Worcestershire, the UK (hereinafter ‘the owner’)</p>
            
      <h1>Use of the website</h1>
      <p>All data and information found on the website, including text generated by third part Generative AI services like ChatGPT, is only made available for informative purposes. Any decisions made based on this information are at one’s own expense and risk. The content of the website includes that generated through the use of third party Generative AI services, the owner can in no way guarantee that the nature and content of the information is correct.
      <p> </p>
      <br></br>Use of the website that may hinder the use of other Internet users, endanger the proper functioning of the website and/or that may affect the information provided on or via the website or underlying software, is strictly prohibited.  </p>
    
      <h1>Hyperlinks to third party websites</h1>
      <p>In the event the website offers hyperlinks to third party websites, this does not necessarily mean that the owner recommends the products or services offered on or via these websites. The accuracy, reasonableness, reliability, truthfulness and completeness of the information on such websites have not been verified by the owner. The owner accepts no obligation or liability for such websites. The use of such hyperlinks is therefore completely at your own expense and risk.  </p>

      <h1>Intellectual Property Rights</h1>
      <p>All copyright and other intellectual property rights related to its content, including text, design, images, logos and sounds, belong to the owner. Without the prior written permission of the owner, the content of the website may not be reproduced, modified and/or published, in any form or by any means whatsoever, or stored in an authorised database. You may print and/or download information on the website for your own personal use.  </p>
      <p>All content on this website, including text, images, and other materials, is provided for general informational purposes only. We strive to ensure that all content is original and does not infringe on the intellectual property (IP) rights of others. However, if you believe that any content on this website violates copyright or other intellectual property rights, please contact us immediately. Upon receiving a valid notification, we will promptly investigate and, if necessary, remove the infringing content. Our intention is to respect the rights of content creators and provide a platform that adheres to all applicable intellectual property laws.</p>
      <p>You may contact us at [info@studentcareerquiz.co.uk] to notify us of any such concerns. </p>

      <h1>Liability</h1>
      <p>The owner is in no way liable for direct, indirect, special, incidental, immaterial or consequential damages, which the visitor or user of the website and/or third party websites may suffer from as a result of the use of information, materials and/or services on the website and/or as a result of the use of information, materials and/or services on (third party) websites connected to the website, regardless of whether the owner has been informed of the possibility of this damage.</p>

      <h1>Privacy</h1>
      <p>The website does not request, store, or process any Personally Identifiable Information (PII) from its users. We are committed to respecting and protecting the privacy of our users. The Website operates in full compliance with all relevant data protection and privacy laws, including but not limited to the General Data Protection Regulation (GDPR) and other applicable local regulations.</p>
      <p>By using this Website, you acknowledge and agree that we do not collect, process, or maintain any PII in connection with your use of the Website. We take the utmost care to ensure that any data provided by users is handled in accordance with the highest privacy standards.</p>
      <p>For more information regarding our privacy practices, please contact info@studentcareerquiz.com.</p>

      <p>The website uses a cookie consent company called CookieYes, to ensure that the website is compliant with GDPR (EU & UK) CCPA/CPRA (California) and VCDPA (Virginia).</p>
      <p> The cookies may be used to help you navigate efficiently and perform certain functions. You will find detailed information about all cookies under each consent category displayed when visiting the site.
      The cookies that are categorized as "Necessary" are stored on your browser as they are essential for enabling the basic functionalities of the site.
      We also could use third-party cookies that help us analyze how you use this website, store your preferences, and provide the content and advertisements that are relevant to you. These cookies will only be stored in your browser with your prior consent.
      You can choose to enable or disable some or all of these cookies but disabling some of them may affect your browsing experience.</p>

      <h1>Applicable Law</h1>
      <p>The website and disclaimer are governed by UK law. All disputes arising from or in connection with this disclaimer shall only be submitted to the competent court in the UK.</p>

      <h1>Changes to these Terms and Conditions </h1>
      <p>Without further notice, the owner reserves the right to change the information offered on or via the website, including the text of this disclaimer. Any changes shall take effect immediately after they have been implemented. As a user of the website, you are automatically bound by these changes and should therefore regularly consult the disclaimer.</p>
      <button onClick={() => handleClick('/')}className="button-home">Home Page </button>  
    </div>
  );
}

export default Terms;
