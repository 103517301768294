// src/HighSchool_advice.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css'; // Ensure App.css is imported
import aboutUsImage from './assets/clean_logo.JPG'; // Import the image


function FirstjobAdvice() {
  const navigate = useNavigate();
  // Define the handleClick function to navigate to different paths
  const handleClick = (path) => {
    navigate(path);
  };
  window.scrollTo(0, 0);
  return (
    <div className="container">
      <div className="title-container">
      <h1>Post-Graduate Career Advice</h1>
      <img src={aboutUsImage} alt="About Us" className="about-us-image" />
      <p>Choosing your next step after university can be both exciting and daunting, but with a bit of guidance, you can make an informed decision that sets you on a rewarding career path. Here’s some advice to help you navigate this crucial phase.</p>
      <button onClick={() => handleClick('/Firstjob_test')}className="button-fj-test">Take the Test </button>       
      <h2>Reflect on Your Degree and Interests</h2>
      <p>Start by considering how your degree aligns with your career interests. For instance, if you’ve studied business or finance, roles in accounting, consulting, or financial analysis might be a natural fit. Think about the subjects you enjoyed most and the skills you developed during your studies. This reflection can help you pinpoint areas where you’ll be both competent and passionate, making your job search more focused and fulfilling.</p>
      <button onClick={() => handleClick('/Firstjob_test')}className="button-fj-test">Take the Test </button>        
      <h2>Research Industry Requirements</h2>
      <p>Different careers have different requirements, so it’s essential to understand what’s expected in your chosen field. For example, if you’re interested in becoming an accountant, you’ll need to pursue professional qualifications such as the ACA (Associate Chartered Accountant) or ACCA (Association of Chartered Certified Accountants). These qualifications are highly regarded and often required for advancement in the field. Research the qualifications relevant to your career of interest and consider whether you’re prepared to undertake any additional exams or training.</p>
      <button onClick={() => handleClick('/Firstjob_test')}className="button-fj-test">Take the Test </button>        
      <h2>Explore Training and Internships</h2>
      <p>Gaining practical experience can significantly boost your employability. Look for internships, graduate schemes, or temporary roles related to your field. These positions not only provide valuable work experience but also help you build a professional network. Many companies offer graduate training programmes that combine work experience with formal training, which can be a great way to ease into your chosen profession while gaining credentials.</p>
      <button onClick={() => handleClick('/Firstjob_test')}className="button-fj-test">Take the Test </button>       
      <h2>Consider Further Education</h2>
      <p>Depending on your career goals, further education might be beneficial. For instance, if you're looking to enter a specialised field like data science or public policy, a relevant postgraduate degree could enhance your qualifications and open up more opportunities. Research various postgraduate courses and consider how they align with your career aspirations. Make sure the programme is well-regarded in your field and offers good prospects for career advancement.</p>
      <button onClick={() => handleClick('/Firstjob_test')}className="button-fj-test">Take the Test </button>        
      <h2>Seek Advice and Network</h2>
      <p>Don’t hesitate to seek advice from career advisors, mentors, and professionals in your field of interest. Networking is crucial—attend industry events, join professional associations, and connect with alumni from your university. These connections can provide valuable insights into the industry, potential job openings, and practical advice on career development.</p>
      
      <p>By carefully considering these factors and taking proactive steps, you’ll be well-equipped to make a decision that supports your career goals and sets you on a path to success. Remember, your early career is a journey of exploration and growth, and each step you take will contribute to your professional development.</p>


      <button onClick={() => handleClick('/')}className="button-home">Home Page </button>  
      </div>

      <div className="footer">
        <h3>____________________________________________________________</h3>
        <ul className="footer-links">
          <li onClick={() => handleClick('/AboutUs')}>About Us</li>
          <li onClick={() => handleClick('/Contact')}>Contact</li>
          <li onClick={() => handleClick('/Terms-and-conditions')}>Terms & Conditions</li>
        </ul>
        <p>&copy; 2024 www.studentcareerquiz.co.uk. All rights reserved.</p>
      </div>
      
    </div>
  );
}

export default FirstjobAdvice;



