// src/Home.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css'; // Ensure App.css is imported
import aboutUsImage from './assets/clean_logo.JPG'; // Import the image

function Home() {
  const navigate = useNavigate();

// Define the handleClick function to navigate to different paths
const handleClick = (path) => {
  navigate(path);
};
window.scrollTo(0, 0);
  return (
    <div className="container">
      <div className="title-container">
        <img src={aboutUsImage} alt="About Us" className="about-us-image" />
        <h1><strong> Get AI powered help to choose subjects and discover career path insights instantly!</strong></h1>
        <p>Unlock your potential with our AI-powered Career Quiz, designed to suggest personalized career paths for students. By leveraging advanced generative AI technology, we provide tailored career inspirations based on your unique responses.</p>
        <h2><strong>How It Works:</strong></h2>
        <p><strong>1. Select the decision stage you are at </strong> <br></br>by clicking one of these buttons below <br></br>  ⬇️</p>
        <button onClick={() => handleClick('/Gcse_advice')}className="button-gc-advice">GCSE</button>
        <button onClick={() => handleClick('/Alevel_advice')}className="button-al-advice">A-Level</button>
        <button onClick={() => handleClick('/Degree_advice')}className="button-dg-advice">Degree</button>
        <button onClick={() => handleClick('/Firstjob_advice')}className="button-fj-advice">First Job</button>
        <button onClick={() => handleClick('/Midcareer_advice')}className="button-mc-advice">Career change</button>
        <p><strong>2. Answer the quiz:</strong> A small set of questions about your preferences for different types of work & careers plus your interests and strengths.</p>
        <p><strong>3. Get immediate Results:</strong> Get your personalized, customized career suggestions in real-time without the need for registration or waiting for results to be emailed.</p>
        <p>Start your journey to a fulfilling career today with The Career Quiz!</p>   
      </div>

      <div className="footer">
        <h3>____________________________________________________________</h3>
        <ul className="footer-links">
          <li onClick={() => handleClick('/AboutUs')}>About Us</li>
          <li onClick={() => handleClick('/Contact')}>Contact</li>
          <li onClick={() => handleClick('/Terms-and-conditions')}>Terms & Conditions</li>
        </ul>
        <p>&copy; 2024 www.studentcareerquiz.co.uk. All rights reserved.</p>
      </div>

    </div>
  );
}

export default Home;
