// src/alevel_advice.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css'; // Ensure App.css is imported
import aboutUsImage from './assets/clean_logo.JPG'; // Import the image


function AlevelAdvice() {
  const navigate = useNavigate();
  // Define the handleClick function to navigate to different paths
  const handleClick = (path) => {
    navigate(path);
  };
  window.scrollTo(0, 0);
  return (
    <div className="container">
      <div className="title-container">
      <h1>Choosing A-Level subjects</h1>
      <img src={aboutUsImage} alt="About Us" className="about-us-image" />
      <h1><strong>Focusing on Your Interests and Future Goals</strong></h1>
      <p>Choosing your subject options at A Levels is an important process that will shape your academic journey and future career. It can feel overwhelming, but with some careful thought and planning, you can make decisions that align with your interests, strengths, and long-term goals.</p>
      <button onClick={() => handleClick('/alevel_test')}className="button-al-test">Take the Test </button>  

      <p>As you move into A Levels, your subject choices become more specialized, and it's essential to consider how they fit together and how they align with your future aspirations. A Levels are a stepping stone to university, so you should start thinking about what you might want to study further and what careers interest you.</p>
      <button onClick={() => handleClick('/alevel_test')}className="button-al-test">Take the Test </button>  
      <p>If you're drawn to STEM (Science, Technology, Engineering, Maths) subjects, for example, it makes sense to choose subjects that complement each other, like Maths, Physics, and Chemistry. These subjects not only go well together but also keep doors open to a wide range of degree courses and careers, from engineering to medicine. On the other hand, if you're more inclined towards the humanities, subjects like History, English, and Politics could be a strong combination, leading to careers in law, journalism, or education.</p>
      <button onClick={() => handleClick('/alevel_test')}className="button-al-test">Take the Test </button>  
      <p>Remember, some university courses have specific A Level requirements. For instance, to study medicine, you usually need Biology and Chemistry. Research the requirements for courses you’re interested in to ensure you choose the right A Levels. Also, consider the workload—choosing subjects that you enjoy and are passionate about will help you stay motivated during your studies.</p>
      
      <button onClick={() => handleClick('/')}className="button-home">Home Page </button>  
      </div>

      <div className="footer">
        <h3>____________________________________________________________</h3>
        <ul className="footer-links">
          <li onClick={() => handleClick('/AboutUs')}>About Us</li>
          <li onClick={() => handleClick('/Contact')}>Contact</li>
          <li onClick={() => handleClick('/Terms-and-conditions')}>Terms & Conditions</li>
        </ul>
        <p>&copy; 2024 www.studentcareerquiz.co.uk. All rights reserved.</p>
      </div>
      
    </div>

    
  );
}

export default AlevelAdvice;



