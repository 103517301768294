// src/HighSchool_advice.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css'; // Ensure App.css is imported
import aboutUsImage from './assets/clean_logo.JPG'; // Import the image


function DegreeAdvice() {
  const navigate = useNavigate();
  // Define the handleClick function to navigate to different paths
  const handleClick = (path) => {
    navigate(path);
  };
  window.scrollTo(0, 0);
  return (
    <div className="container">
      <div className="title-container">
      <h1>Choosing degree courses </h1>
      <img src={aboutUsImage} alt="About Us" className="about-us-image" />
      <h1><strong>Preparing for Your Future Career</strong></h1>
      <button onClick={() => handleClick('/Degree_test')}className="button-dg-test">Take the Test </button>  
      <p>Choosing your subject options for your undergraduate degree is an important process that will shape your academic journey and future career. It can feel overwhelming, but with some careful thought and planning, you can make decisions that align with your interests, strengths, and long-term goals.</p>
      <button onClick={() => handleClick('/Degree_test')}className="button-dg-test">Take the Test </button> 
      
      <p>Choosing a degree subject is perhaps the most significant decision in your academic journey, as it directly influences your career path. By this stage, you should have a clearer idea of what subjects you excel in and enjoy, as well as what kind of job you might like after university.</p>
      <button onClick={() => handleClick('/Degree_test')}className="button-dg-test">Take the Test </button> 
      <p>It’s important to research thoroughly. Think about the type of career you want—do you see yourself in a highly specialized role like a doctor, engineer, or lawyer, or are you drawn to more flexible fields like business, arts, or social sciences? Some careers, like medicine, require specific degrees and entry tests (such as the UCAT or BMAT for medicine), so make sure you’re aware of these requirements well in advance.</p>
      <button onClick={() => handleClick('/Degree_test')}className="button-dg-test">Take the Test </button> 
      <p>If you’re unsure about your career path, consider degrees that offer versatility and transferable skills, such as Business, English, or Psychology. These degrees can lead to a variety of careers and give you the flexibility to explore different options. Alternatively, vocational degrees, like Nursing or Engineering, are more directly aligned with specific careers and might suit you if you have a clear idea of your future profession.</p>
      <button onClick={() => handleClick('/Degree_test')}className="button-dg-test">Take the Test </button> 
      <p>In the end, choose a subject that you are passionate about, as you’ll be dedicating several years to studying it in depth. University is also a time to develop other skills, such as teamwork, communication, and problem-solving, which are highly valued by employers. Think of your degree as not just an academic qualification, but as a way to prepare yourself for the working world.</p>
  
      <button onClick={() => handleClick('/')}className="button-home">Home Page </button>  
      </div>

      <div className="footer">
        <h3>____________________________________________________________</h3>
        <ul className="footer-links">
          <li onClick={() => handleClick('/AboutUs')}>About Us</li>
          <li onClick={() => handleClick('/Contact')}>Contact</li>
          <li onClick={() => handleClick('/Terms-and-conditions')}>Terms & Conditions</li>
        </ul>
        <p>&copy; 2024 www.studentcareerquiz.co.uk. All rights reserved.</p>
      </div>
      
    </div>
  );
}

export default DegreeAdvice;



