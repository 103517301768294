// src/AboutUs.js

import React from 'react';
import aboutUsImage from './assets/clean_logo.JPG'; // Import the image
import { useNavigate } from 'react-router-dom';

function AboutUs() {

  const navigate = useNavigate();

  // Define the handleClick function to navigate to different paths
  const handleClick = (path) => {
    navigate(path);
  };
  window.scrollTo(0, 0);
  return (
    <div className="about-us-container">
      
      <h1>About Us</h1>
      <img src={aboutUsImage} alt="About Us" className="about-us-image" />
      <p>Welcome to Student career Quiz, the career guidance platform! Our mission is to help individuals find the right career path by using advanced AI technology.</p>
      <p>We aim to provide personalized career advice to students and professionals alike. Our platform is constantly improving, and we are here to help you on your career journey.</p>
      <p>  </p>
      <p>As a parent with children in the UK, I found it difficult to find good quality resources to help my children make important decisions about their futures. Which subjects to choose at each ket stage and how to present to them a full range of ideas in terms of the possible career opportunities that are out there.  With my background in technology and a passion for using data analytics to provide valuable insights, I decided to create this platform. My goal is to use AI technology to assist young people like you in choosing the right subjects at GCSE, A-Level, and university, as well as exploring potential first jobs. I wanted to generate some ideas that gets people thinking beyond the basic professions of Teacher, Doctor, Vet etc. I'm committed to offering this resource at my own expense, free of charge, in the hope that it can make a difference. If I can help even one person, it will all have been worth it.</p>
      <p>  </p>
      <p>Please take a moment to read the website terms & conditions below before using. </p>

      <button onClick={() => handleClick('/')}className="button-home">Home Page </button>  

      <div className="footer">
        <h3>____________________________________________________________</h3>
        <ul className="footer-links">
          <li onClick={() => handleClick('/AboutUs')}>About Us</li>
          <li onClick={() => handleClick('/Contact')}>Contact</li>
          <li onClick={() => handleClick('/Terms-and-conditions')}>Terms & Conditions</li>
        </ul>
        <p>&copy; 2024 www.studentcareerquiz.co.uk. All rights reserved.</p>
      </div>
      
    </div>

    
  );
}

export default AboutUs;
