// src/HighSchool_advice.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css'; // Ensure App.css is imported
import aboutUsImage from './assets/clean_logo.JPG'; // Import the image



function GcseAdvice() {
  const navigate = useNavigate();
  // Define the handleClick function to navigate to different paths
  const handleClick = (path) => {
    navigate(path);
  };
  
  window.scrollTo(0, 0);

  return (
    <div className="container">
      <div className="title-container">
      <h1>GCSE Options</h1>
      <img src={aboutUsImage} alt="About Us" className="about-us-image" />
      <h1><strong>Building a Strong Foundation</strong></h1>
      
      <p>Choosing your subject options at GCSE is an important process that will shape your academic journey and future career. It can feel overwhelming, but with some careful thought and planning, you can make decisions that align with your interests, strengths, and long-term goals.</p>
      <button onClick={() => handleClick('/Gcse_test')}className="button-gc-test">Take the Test </button>  
      
      <p>When it comes to choosing your GCSE subjects, it's wise to maintain a broad and balanced selection. This is your opportunity to explore different areas and discover what you enjoy and where your strengths lie. Most students will take core subjects like English, Maths, and Science, but you’ll also have options to choose from areas like Humanities (Geography, History), Languages, Arts (Music, Drama), and Technology (ICT, Design and Technology).</p>
      <button onClick={() => handleClick('/Gcse_test')}className="button-gc-test">Take the Test </button>  
      <p>A broad range of subjects at GCSE keeps your options open for the future. For instance, even if you think you might want to pursue a career in science, taking a language or a humanities subject can be valuable. Employers and universities often look for well-rounded individuals who can think critically and adapt to different fields. Moreover, some subjects, like a modern foreign language, might be required for certain A Level courses or university applications, so it’s good to keep that in mind.</p>

      <button onClick={() => handleClick('/')}className="button-home">Home Page </button>  
      </div>

      <div className="footer">
        <h3>____________________________________________________________</h3>
        <ul className="footer-links">
          <li onClick={() => handleClick('/AboutUs')}>About Us</li>
          <li onClick={() => handleClick('/Contact')}>Contact</li>
          <li onClick={() => handleClick('/Terms-and-conditions')}>Terms & Conditions</li>
        </ul>
        <p>&copy; 2024 www.studentcareerquiz.co.uk. All rights reserved.</p>
      </div>
      
    </div>
  );
}

export default GcseAdvice;



