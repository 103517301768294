// src/Survey.js

import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function Survey({ careerStage }) {
  
  const [data, setData] = useState({ members: [] });
  const [isChecked, setIsChecked] = useState(false); // Checkbox state
  const [isLoading, setIsLoading] = useState(false);
  const [responses, setResponses] = useState(Array(30).fill(2)); // Default value set to 3 for all sliders
  const [analysis, setAnalysis] = useState(''); // State to hold the analysis reply
  const analysisRef = useRef(null); // Ref for the analysis container
  const [dropdown1, setdropdown1] = useState('');
  const [dropdown2, setdropdown2] = useState('');
  const [dropdown3, setdropdown3] = useState('');

  useEffect(() => {
    //fetch(`http://localhost:5000/members?careerStage=${careerStage}`)
    fetch(`${process.env.REACT_APP_BACKEND_URL}/members?careerStage=${careerStage}`)
    //fetch(`${process.env.REACT_APP_BACKEND_URL}/members`)  // Fetch from your Flask backend
      .then(res => res.json())
      .then(data => {
        setData(data);  // Update state with fetched data
        console.log('fetch data from ',  careerStage);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  

  // Handle checkbox change
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };


  const handleSliderChange = (index, value) => {
    const newResponses = [...responses];
    newResponses[index] = parseInt(value);
    setResponses(newResponses);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    
    if (!isChecked) {
      alert('Please accept the terms of use.');
      return;
    }

    setIsLoading(true); // Set loading to true when the process starts
    // Generate a unique respondent ID (you can use UUID or any other method)
    const respondentId = generateRespondentId();
    console.log(`print js Event : ${event}`);
    console.log(`print js careerStage : ${careerStage}`);
    //fetch(`http://localhost:5000/submit?careerStage=${careerStage}`, {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/submit?careerStage=${careerStage}`, {
    //fetch(`${process.env.REACT_APP_BACKEND_URL}/submit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ respondent_id: respondentId, responses: responses, dropdown1:dropdown1 , dropdown2:dropdown2, dropdown3:dropdown3})
    })
    .then(res => res.json())
    .then(response => {
      console.log('Success:', response);
      // Scroll to the analysis container
      if (analysisRef.current) {
        analysisRef.current.scrollIntoView({ behavior: 'smooth' });
        } 
      if (response.reply) {
        setAnalysis(response.reply); // Set the analysis reply to state
      }
    })
    .catch(error => {
      console.error('Error:', error);
    })
    .finally(() => {
      setIsLoading(false); // Set loading to false when the process is complete
    });
  };

  const generateRespondentId = () => {
    // Example of generating a simple unique ID
    return 'RESP_' + Math.random().toString(36).substr(2, 9);
  };

  

  return ( 
    
    <div>
     
      <div className="container">
        
        


        {data.members.length === 0 ? (
          <p>Loading...</p>
        ) : (
          <div className="main-content">
            <form className="form-container" onSubmit={handleSubmit}>

            <h2>Select three subjects you have studied or the three you enjoy the most </h2>
            <div className='dropdown-container'>
              <select className="dropdown1" value={dropdown1} onChange={(e) => setdropdown1(e.target.value)}>
                <option value="">Select Subject 1 </option>
                <option value="mathematics">Mathematics</option>
                <option value="biology">Biology</option>
                <option value="psychology">Psychology</option>
                <option value="chemistry">Chemistry</option>
                <option value="history">History</option>
                <option value="english-literature">English Literature</option>
                <option value="physics">Physics</option>
                <option value="sociology">Sociology</option>
                <option value="business-studies">Business Studies</option>
                <option value="geography">Geography</option>
                <option value="economics">Economics</option>
                <option value="art-and-design">Art and Design</option>
                <option value="political-science">Political Science</option>
                <option value="computer-science">Computer Science</option>
                <option value="religious-studies">Religious Studies</option>
                <option value="media-studies">Media Studies</option>
                <option value="modern-language">Modern Language</option>
                <option value="law">Law</option>
                <option value="physical-education">Physical Education</option>
                <option value="music">Music</option>
              </select>


                <select className="dropdown2" value={dropdown2} onChange={(e) => setdropdown2(e.target.value)}>
                <option value="">Select Subject 2 </option>
                <option value="mathematics">Mathematics</option>
                <option value="biology">Biology</option>
                <option value="psychology">Psychology</option>
                <option value="chemistry">Chemistry</option>
                <option value="history">History</option>
                <option value="english-literature">English Literature</option>
                <option value="physics">Physics</option>
                <option value="sociology">Sociology</option>
                <option value="business-studies">Business Studies</option>
                <option value="geography">Geography</option>
                <option value="economics">Economics</option>
                <option value="art-and-design">Art and Design</option>
                <option value="political-science">Political Science</option>
                <option value="computer-science">Computer Science</option>
                <option value="religious-studies">Religious Studies</option>
                <option value="media-studies">Media Studies</option>
                <option value="modern-language">Modern Language</option>
                <option value="law">Law</option>
                <option value="physical-education">Physical Education</option>
                <option value="music">Music</option>
              </select>

              <select className="dropdown3" value={dropdown3} onChange={(e) => setdropdown3(e.target.value)}>
                <option value="">Select Subject 3 </option>
                <option value="mathematics">Mathematics</option>
                <option value="biology">Biology</option>
                <option value="psychology">Psychology</option>
                <option value="chemistry">Chemistry</option>
                <option value="history">History</option>
                <option value="english-literature">English Literature</option>
                <option value="physics">Physics</option>
                <option value="sociology">Sociology</option>
                <option value="business-studies">Business Studies</option>
                <option value="geography">Geography</option>
                <option value="economics">Economics</option>
                <option value="art-and-design">Art and Design</option>
                <option value="political-science">Political Science</option>
                <option value="computer-science">Computer Science</option>
                <option value="religious-studies">Religious Studies</option>
                <option value="media-studies">Media Studies</option>
                <option value="modern-language">Modern Language</option>
                <option value="law">Law</option>
                <option value="physical-education">Physical Education</option>
                <option value="music">Music</option>
              </select>


              </div>

              <div className="submit-container">
                {/* Terms of Use checkbox */}
                <div className="terms-container">
                  <input
                    type="checkbox"
                    id="terms-checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="terms-checkbox">
                    Click to accept{' '}
                    <a href="/Terms-and-conditions" target="_blank" rel="noopener noreferrer">
                      Terms of Use
                    </a>
                  </label>
                </div>

                {/* Submit Button */}
                <button type="submit" disabled={!isChecked || isLoading}>
                  Submit
                </button>

                {isLoading && (
                <div style={{ textAlign: 'center', margin: '20px 0' }}>
                  <FontAwesomeIcon icon={faSpinner} spin size="4x" />
                  <p>AI is generating recommendations... Please wait.</p>
                  
                </div>
                )}
              </div>

              <p>  </p>
              <h2 style={{ background: 'lightblue' }} > Optionally, to provide additional context, answer these Yes / No questions using the slide bar below: </h2>
              
            
              {data.members.map((member, i) => (
                <div key={i} className="member-container">
                  <h3> </h3>
                  <label htmlFor={`member-${i}`}>{member}</label>
                  <div className="slider-labels">
                    <span>No</span>
                    <span> (not sure) </span>
                    <span>Yes </span>
                  </div>
                  <input
                    type="range"
                    id={`member-${i}`}
                    name={`member-${i}`}
                    min="1"
                    max="3"
                    value={responses[i]}
                    onChange={(e) => handleSliderChange(i, e.target.value)}
                    className={
                      responses[i] > 2 ? 'green-slider' :
                      responses[i] < 2 ? 'red-slider' :                    
                      'grey-slider'
                    }
                  />  
                  
                  
                  
                </div>
              ))}
              
              


              <div className="submit-container">
                {/* Terms of Use checkbox */}
                <div className="terms-container">
                  <input
                    type="checkbox"
                    id="terms-checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="terms-checkbox">
                    Click to accept{' '}
                    <a href="/Terms-and-conditions" target="_blank" rel="noopener noreferrer">
                      Terms of Use
                    </a>
                  </label>
                </div>

                {/* Submit Button */}
                <button type="submit" disabled={!isChecked || isLoading}>
                  Submit
                </button>
              </div>
            </form>
            {isLoading && (
              <div style={{ textAlign: 'center', margin: '20px 0' }}>
                <FontAwesomeIcon icon={faSpinner} spin size="4x" />
                <p>AI is generating recommendations... Please wait.</p>
                
              </div>
            )}
            <div ref={analysisRef} className="analysis-container">
              <h3>AI generated Recommendations *</h3>
              <textarea value={analysis} readOnly rows="20" cols="50" />
              
              <h3>Do you like any of these suggestions?</h3>
              <h3>If you do, find out more by searching online</h3>
              <h3>If you don't then try modifying the responses above and try again!</h3>
            </div>
            <div className="footer">
            <p>* ChatGPT can make mistakes. Check important info.</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );

}

export default Survey;
